import React, { FC, useState } from "react"
import { SelectorIcon } from "@heroicons/react/solid"
import { useClassNames } from "hooks"
import { Combobox } from "@headlessui/react"

export type SelectCantonOption = {
  key: string
  name: string
  imageUrl?: string
}

type Props = {
  label: string
  placeholder?: string
  selected: SelectCantonOption[]
  options: SelectCantonOption[]
  onClick?: (value?: SelectCantonOption) => void
  removeItem?: (value?: SelectCantonOption) => void
}

export const SearchCombobox: FC<Props> = ({
  label,
  selected,
  options,
  placeholder,
  onClick,
  removeItem,
}) => {
  const classNames = useClassNames()

  const [query, setQuery] = useState("")

  const filteredOptions =
    query === ""
      ? options
      : options.filter((option) => {
        return option?.name.toLowerCase().includes(query.toLowerCase())
      })

  return (
    <>
      <Combobox as="div" value="" onChange={() => {}}>
        <Combobox.Label className="block text-sm font-medium text-gray-700">
          {label}
        </Combobox.Label>
        <div className="relative mt-1">
          <Combobox.Input
            className="w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500 sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none">
            <SelectorIcon
              className="w-5 h-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredOptions.length > 0 && (
            <Combobox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.map((option) => (
                <Combobox.Option
                  key={option.key}
                  value={option}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-primary-600 text-white" : "text-gray-900"
                    )
                  }
                  onClick={() => onClick && onClick(option)}
                >
                  {option?.name}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      <div className="flex flex-wrap items-center -m-1">
        {selected.length > 0 &&
          selected.map((canton) => (
            <span
              key={canton?.name}
              className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
            >
              <span>{canton?.name}</span>
              <button
                type="button"
                className="inline-flex flex-shrink-0 w-4 h-4 p-1 ml-1 text-gray-400 rounded-full hover:bg-gray-200 hover:text-gray-500"
                onClick={() => removeItem && removeItem(canton)}
              >
                <span className="sr-only">Remove filter for {canton?.name}</span>
                <svg
                  className="w-2 h-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    strokeLinecap="round"
                    strokeWidth="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </span>
          ))}
      </div>
    </>
  )
}
