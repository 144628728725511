import React, { FC, MouseEvent, useMemo } from "react"

import { useClassNames } from "hooks/useClassNames"

type Props = {
  className?: string
  onClick: (e: MouseEvent) => void
  children: React.ReactNode
  variant?: "primary" | "secondary" | "white"
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  loading?: boolean
  round?: boolean
  block?: boolean
  disabled?: boolean
}

export const Button: FC<Props> = ({
  className = "",
  children,
  loading = false,
  round = false,
  variant = "primary",
  size = "md",
  block = false,
  disabled,
  onClick,
  ...rest
}) => {
  const classNames = useClassNames()

  const handleClick = (e: MouseEvent) => {
    if (!disabled) {
      onClick(e)
    }
  }

  const widthClasses = useMemo(() => {
    if (block) {
      return "w-full justify-center"
    } else return ""
  }, [block])

  const textClasses = useMemo(() => {
    switch (size) {
    case "xs":
      return "text-xs font-medium"
    case "sm":
      return "text-sm leading-4 font-medium"
    case "md":
      return "text-sm font-medium"
    case "lg":
      return "text-base font-medium"
    case "xl":
      return "text-base font-medium"
    }
  }, [size])

  const colorClasses = useMemo(() => {
    switch (variant) {
    case "primary":
      return "border-transparent text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500"
    case "secondary":
      return "border-transparent text-primary-700 bg-primary-100 hover:bg-primary-200 focus:ring-primary-500"
    case "white":
      return "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary-500"
    }
  }, [variant])

  const paddingClasses = useMemo(() => {
    switch (size) {
    case "xs":
      return "px-2.5 py-1.5"
    case "sm":
      return "px-3 py-2"
    case "md":
      return "px-4 py-2"
    case "lg":
      return "px-4 py-2"
    case "xl":
      return "px-6 py-3"
    }
  }, [size])

  const borderClasses = useMemo(() => {
    if (round) return "rounded-full"
    if (size === "xs") return "rounded"
    return "rounded-md"
  }, [size, round])

  const buttonClass = useMemo(() => {
    return classNames(
      widthClasses,
      textClasses,
      colorClasses,
      paddingClasses,
      borderClasses,
      "shadow-sm border inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2",
      className
    )
  }, [textClasses, className, widthClasses, colorClasses, paddingClasses, borderClasses])

  return (
    <button type="button" className={buttonClass} onClick={handleClick} {...rest}>
      {!loading && <>{children}</>}
      {loading && <img className="w-5 h-5" src="/images/spin.svg" alt="Spinner" />}
    </button>
  )
}