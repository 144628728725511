import React, { FC } from "react"
import { ExclamationCircleIcon } from "@heroicons/react/solid"

import { useClassNames } from "hooks/useClassNames"

// eslint-disable-next-line
interface Props {
  id: string
  label?: string
  value: string
  // eslint-disable-next-line
  onChange: (value: string) => void
  placeholder?: string
  hasError?: boolean
  errorText?: string
  inputMode?: "numeric" | "text"
}

export const Textarea: FC<Props> = ({
  id,
  label,
  value,
  onChange,
  hasError,
  errorText,
  placeholder,
  inputMode = "text",
  ...rest
}) => {
  const classNames = useClassNames()
  return (
    <div>

      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}

      <div className="relative mt-1">
        <textarea
          id={id}
          rows={4}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          inputMode={inputMode}
          className={classNames(
            hasError
              ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
              : "border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500",
            "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm  focus:outline-none   sm:text-sm"
          )}
          placeholder={placeholder}
          {...rest}
        />

        {hasError && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon className="w-5 h-5 text-red-500" aria-hidden="true" />
          </div>
        )}
      </div>

      {hasError && (
        <div className="h-5 mt-1">
          <p className="text-sm text-red-600" id="email-error">
            {errorText}
          </p>
        </div>
      )}
    </div>
  )
}