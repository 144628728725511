import React, { FC, useMemo } from 'react'
import Image from 'next/image'
import { useClassNames } from 'hooks'

type Props = {
  imageUrl?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  className?: string
  alt?: string
}

export const Avatar: FC<Props> = ({
  imageUrl,
  className = '',
  size = 'md',
  alt,
}) => {
  const classNames = useClassNames()

  const sizeClasses = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'h-6 w-6'
      case 'sm':
        return 'h-8 w-8'
      case 'md':
        return 'h-10 w-10'
      case 'lg':
        return 'h-12 w-12'
      case 'xl':
        return 'h-14 w-14'
    }
  }, [size])

  const avatarClass = useMemo(() => {
    return classNames(
      sizeClasses,
      'inline-block relative rounded-full overflow-hidden bg-gray-100',
      className
    )
  }, [sizeClasses, className])

  return (
    <>
      <span className={avatarClass}>
        {imageUrl && (
          <Image
            fill
            sizes={sizeClasses}
            className="object-cover w-full h-full"
            src={imageUrl}
            alt={alt || 'avatar'}
          />
        )}
        {!imageUrl && (
          <svg
            className="w-full h-full text-gray-300"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        )}
      </span>
    </>
  )
}
