import React, { FC } from "react"

type Props = {
  id: string
  label: string
  value: string
  onChange: (key: string) => void
  elements: { key: string; text: string }[]
}

export const RadioInput: FC<Props> = ({ elements, label, onChange, value }) => {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <fieldset className="mt-1">
        <legend className="sr-only">{label}</legend>
        <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
          {elements.map(({ key, text }) => (
            <div key={key} className="flex items-center">
              <input
                id={key}
                name="notification-method"
                type="radio"
                checked={value === key}
                onChange={() => onChange(key)}
                className="w-4 h-4 border-gray-300 focus:ring-primary-500 text-primary-600"
              />
              <label htmlFor={key} className="block ml-2 text-sm font-medium text-gray-700">
                {text}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}