import React, { FC, Fragment, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { useClassNames } from 'hooks'
import { Avatar } from './Avatar'

export type SelectOption = {
  key: string
  text: string
  imageUrl?: string
}

type Props = {
  className?: string
  label: string
  placeholder?: string
  selected?: SelectOption | null
  options: SelectOption[]
  onChange: (value?: SelectOption) => void
}

export const Select: FC<Props> = ({
  className,
  label,
  selected,
  options,
  placeholder,
  onChange,
}) => {
  const classNames = useClassNames()

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <div className={className}>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
              <span className="flex items-center h-5">
                {selected && (
                  <>
                    {selected?.imageUrl && (
                      <Avatar
                        imageUrl={selected?.imageUrl}
                        size={'xs'}
                        className="flex-shrink-0"
                        alt={selected.text}
                      />
                    )}
                    <span className="block ml-3 truncate">{selected.text}</span>
                  </>
                )}
                {!selected && (
                  <span className="block ml-3 truncate">
                    {placeholder || <>&nbsp;</>}
                  </span>
                )}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                <SelectorIcon
                  className="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((item) => (
                  <Listbox.Option
                    key={item.key}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-primary-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => {
                      // Debugging log
                      return (
                        <>
                          <div className="flex items-center">
                            {item?.imageUrl && (
                              <div className="flex-shrink-0 object-cover w-6 h-6 rounded-full">
                                <Avatar
                                  imageUrl={item?.imageUrl}
                                  size={'xs'}
                                  className="flex-shrink-0 "
                                />
                              </div>
                            )}
                            <span
                              className={classNames(
                                selected ? 'font-semibold' : 'font-normal',
                                'ml-3 block truncate'
                              )}
                            >
                              {item.text}
                            </span>
                          </div>

                          {selected && (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-primary-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              {selected && <CheckIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />}
                            </span>
                          )}
                        </>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  )
}
